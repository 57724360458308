import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import { navigate } from "gatsby"

import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import QuoteBox from "../../../../../components/quote-box"
import Seo from "../../../../../components/seo"
import Small from "../../../../../components/small"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"
import Textarea from "../../../../../components/textarea"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query ArgumenteSammelnQuery {
      poster: file(relativePath: { eq: "kurse/poster-knubben.png" }) {
        ...largeImage
      }
    }
  `)

  const taskProps = {
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "argumente-sammeln",
  }

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
        })
        navigate(
          "/kurse/reiseerinnerung/01-suedfrankreich/argumente-sammeln/zusammenfassung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Argumente sammeln" />
      <Stack>
        <Heading as="h2" level={2}>
          Argumente sammeln
        </Heading>
        <PaperStack>
          <Poem>
            <p>Es nehmet aber</p>
            <p>Und giebt Gedächtniß die See, </p>
            <p>Und die Lieb’ auch heftet fleißig die Augen, </p>
            <p>Was bleibet aber, stiften die Dichter.</p>
          </Poem>
        </PaperStack>
        <Paragraph>
          Poetische Zeilen, die zugleich jedoch viele Fragen aufwerfen. Warum
          nimmt und gibt die See Gedächtnis? Wolfgang Binder hat diese Zeilen
          wie folgt ausgelegt:
        </Paragraph>
        <Stack space={3}>
          <QuoteBox>
            Wer zur See fährt, muß die Heimat vergessen können, richtiger
            vielleicht das Heimweh, aber draußen muß er sich doch ihrer wieder
            erinnern; denn wie käme er sonst zurück?
          </QuoteBox>
          <Text sans color="whisper">
            Wolfang Binder: Hölderlin: ›Andenken‹, in: Turm-Vorträge 1985/86,
            hrsg. von Uvo Hölscher, Hölderlin-Gesellschaft Tübingen 1986, S.
            5-30.
          </Text>
        </Stack>
        <Paragraph>
          Das lässt sich selbstverständlich auf Reisen aller Art übertragen.
          Welche Hoffnungen setzte Hölderlin deiner Meinung nach in die Reise
          nach Frankreich? Denke an die Briefe, die er vor und während der Reise
          nach Bordeaux verschickt hat.
        </Paragraph>
        <Paragraph>
          Der Literaturwissenschaftler Thomas Knubben ist Hölderlins Reiseroute
          nach Bordeaux im Winter 2011 selbst nachgelaufen. In diesem Video
          erzählt er von Hölderlins Beweggründen zu dieser Reise.
        </Paragraph>
        <Stack space={3}>
          <VideoPlayer src="/kurse/interview-knubben" poster={data.poster} />
          <Small color="whisper">
            Ein Film der Universität Tübingen, Institut für Medienwissenschaft
            Prof. Dr. Susanne Marschall
          </Small>
        </Stack>
        <TaskSidebar
          main={
            <Stack>
              <Textarea
                id="reise-gibt"
                name="reise-gibt"
                label="Was versprach sich Hölderlin von der Reise?"
                rows={8}
                placeholder="Notizen hier schreiben..."
              />
              <Textarea
                id="reise-nimmt"
                name="reise-nimmt"
                label="Was war er bereit dafür aufzugeben?"
                rows={8}
                placeholder="Notizen hier schreiben..."
              />
            </Stack>
          }
          sidebar={
            <Note variant="task">
              Sammle einige Argumente, die Hölderlin zum Aufbruch nach Bordeaux
              bewegt haben könnten. Was versprach er sich von der Reise? Was war
              er bereit, dafür zu geben? Notiere deine Argumente in die
              Textfelder.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
